.container {
    height: 80px;
    width: 100%;
    min-width: 300px;
    margin-bottom: 8px;
}
.character, .character-invalid, .character-inactive, .character-selected {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Public Sans Variable', sans-serif;
    font-size: 48px;
    font-weight: 56px;
    border-radius: 4px;
   
}
.character {
    padding: 1px;
    border: 1px solid #686875;
    color: #15151A;
}
.character-invalid {
    padding: 0;
    border: 2px solid #CC2A18;
    color: #15151A;
}
.character-inactive {
    padding: 1px;
    border: 1px solid #C2C2CC;
    background-color: #D7D7E0;
    color: #888894;
}
.character-selected {
    padding: 0;
    position: relative;
    border: 2px solid #2323DB;
    outline: none !important;
    color: #15151A;
}
/* optionally hide placeholder (in case it overlaps the cursor) */
.character-selected:not(.character--filled) {
    color: transparent;
}
.character-selected:not(.character--filled)::after {
    content: "";
    width: 1px;
    height: 50px;
    background-color: #000000;
    display: block;
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    animation: blink 1s infinite step-end;
}
.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

@keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
